<template>
  <div class="subjective_view">
    <div class="interMant_top">
      <div class="interTop_left">
        <!-- <el-avatar size="large" :src="'https://' + infoData.head_img"></el-avatar> -->
        <el-avatar :size="50">
          <img :src="infoData.head_img ? 'http://' + infoData.head_img : circleUrl"/>
        </el-avatar>
        <div class="otim">
          姓名:
          <span>{{infoData.name}}</span>
          性别:
          <span>{{infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : ''}}</span>
          身高:
          <span>{{infoData.height}}cm</span>
          体重:
          <span>{{infoData.weight}}kg</span>
          项目:
          <span>{{infoData.sport_name}}</span>
          运动队:
          <span>{{infoData.department_name}}</span>
        </div>
      </div>
      <div class="interTop_right">
        <el-date-picker
          v-model="value1"
          @change="dateChange"
          :clearable="false"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="customPickerOptions"
          :unlink-panels="true">
        </el-date-picker>
        <span @click="reBack">
          <i class="iconfont iconfanhui"></i>
          返回
        </span>
      </div>
    </div>

    <div class="subjective_content" v-loading='Loading'>
      <div class="content_top_left">
        <p class="jump">
          垂直纵跳（cm）<el-input class="jump-a" v-model="from.jump" @blur="statusChange(from.jump, 4)" @input="valueChange('jump')" :disabled="edit"></el-input>
        </p>
        <div id="echartsFirst" class="echarts"></div>
        <div style="position: absolute;top:50%;left:47%" v-if="zwsjOne"> 暂无数据</div>
      </div>
      <div class="content_top_left">
        <p>
          握力（kg）<el-input v-model="from.grip" @blur="statusChange(from.grip, 6)" @input="valueChange('grip')" :disabled="edit"></el-input>
        </p>
        <div id="echartsCenter" class="echarts"></div>
        <div style="position: absolute;top:50%;left:47%" v-if="zwsjTwo"> 暂无数据</div>
      </div>
      <div class="content_top_left">
        <p>
          力量反应指数/RSI
          <el-input v-model="from.cmj" @blur="statusChange(from.cmj, 5)" @input="valueChange('cmj')" :disabled="edit"></el-input>
        </p>
        <div id="echartsRight" class="echarts"></div>
        <div style="position: absolute;top:50%;left:47%" v-if="zwsjThree"> 暂无数据</div>
      </div>
      <div class="content_top_left">
        <p>
          10s踏频（次）
          <el-input v-model="from.frecy" @blur="statusChange(from.frecy, 7)" @input="valueChange('frecy')" :disabled="edit"></el-input>
        </p>
        <div id="echartsBot" class="echarts"></div>
        <div style="position: absolute;top:50%;left:47%" v-if="zwsjFour"> 暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zwsjOne:false,//暂无数据
      zwsjTwo:false,//暂无数据
      zwsjThree:false,//暂无数据
      zwsjFour:false,//暂无数据
      Loading:true,
      edit: this.$route.query.edit == '1' ? true : false,
      infoData: {},
      value1: new Date(),
      customDateArr: [],
      from: {},
      value: [],
      echartsData: {},
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    }
  },
  mounted() {
    this.serveInfo()
    this.create()
    this.getDate()
  },
  computed:{
    customPickerOptions () {
      let that = this
      that.type = true
      return {
        cellClassName (date) {
          if (that.customDateArr.includes(that.getFullDate(date))) {
            return 'custom_date_class'
          }
        }
      }
    }
  },
  methods: {
    dateChange(date) {
      this.create()
      this.getDate()
    },
    getDate(startDate, endDate) {
      this.$axios
        .post(
          "/p/statusrecord/getDate",
          this.$qs({
            staff_uuid: this.$route.params.uuid,
            cate: 2
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.customDateArr = res.data.data
          }
        })
    },
    // 格式化日期格式为 xxxx-xx-xx
    getFullDate(targetDate) {
      var D, y, m, d;
      if (targetDate) {
          D = new Date(targetDate);
          y = D.getFullYear();
          m = D.getMonth() + 1;
          d = D.getDate();
      } else {
          y = fullYear;
          m = month;
          d = date;
      }
      m = m > 9 ? m : '0' + m;
      d = d > 9 ? d : '0' + d;
      return y + '-' + m + '-' + d;
    },

    serveInfo() {
      this.$axios
        .post(
          "/p/injury/staffInfo",
          this.$qs({
            uuid: this.$route.params.uuid
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.infoData = res.data.data[0];
            this.Loading = false
          }
        });
    },
    reBack() {
      this.$router.push({path: '/conditionModule/sportsExpression', query: {
        type: 2
      }})
    },
    setEcharts() {
      let areaOne = 0, areaTwo = 0, areaThree = 0, areaFour = 0, numOne = 0, numTwo = 0, numThree = 0, numFour = 0
      if(this.echartsData) {
        // numOne =  this.echartsData[4] && this.echartsData[4].length
        // numTwo = this.echartsData[5] && this.echartsData[5].length
        // numThree = this.echartsData[6] && this.echartsData[6].length
        // numFour = this.echartsData[7] && this.echartsData[7].length
        this.echartsData[4] && this.echartsData[4].forEach(item => {
          if(item.value) numOne++
          areaOne += Number(item.value)
        })
        if(areaOne==0){
          this.zwsjOne = true
        }else{
          this.zwsjOne = false
        }
        this.echartsData[6] && this.echartsData[6].forEach(item => {
          if(item.value) numTwo++
          areaTwo += Number(item.value)
        })
        if(areaTwo==0){
          this.zwsjTwo = true
        }else{
          this.zwsjTwo = false
        }
        this.echartsData[5] && this.echartsData[5].forEach(item => {
          if(item.value) numThree++
          areaThree += Number(item.value)
        })
        if(areaThree==0){
          this.zwsjThree = true
        }else{
          this.zwsjThree = false
        }
        this.echartsData[7] && this.echartsData[7].forEach(item => {
          if(item.value) numFour++
          areaFour += Number(item.value)
        })
        if(areaFour==0){
          this.zwsjFour = true
        }else{
          this.zwsjFour = false
        }
      }
      let echartsFirst = this.$echarts.init(
       document.getElementById("echartsFirst")
      );
      let option = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '5%',
            bottom: '5%',
            top: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.echartsData[4] && this.echartsData[4].map(item => {
              return {
                value: item.date,
                textStyle: {
                    color: '#585D66'
                }
              }
            }),
            axisLabel: {
              interval: 0,
              padding: [10, 0, 0, 0]
            },
            axisLine: {
              lineStyle: {
                color: '#999'
              }
            },
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: '#000'
              }
            },
            nameTextStyle: {
              color: '#000',
            }
        },
        yAxis: {
            type: 'value',
            name: 'cm',
            axisLine: {
              lineStyle: {
                color: '#585D66'
              }
            },
            min: 'dataMin',
            max: 'dataMax',
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true
            }
        },
        color: ['#0055E9'],
        series: [
            {
              type: 'line',
              data: this.echartsData[4] && this.echartsData[4].map(item => {
                return item.value
              }),
              symbol: 'circle', //标记的图形
              symbolSize: 25, // 拐点的大小
              label: {
                normal: {
                  show: true,
                  color: '#fff', // 数字颜色
                  position: ['50%', '35%'],
                  offset: [0, 0],
                  align: 'center'
                }
              },
              markLine:{       //平均线设置
                  silent:true,    //true 去掉鼠标悬浮该线上的动画
                  symbol:"none",   //该线无样式
                  label:{
                      width: '1',
                      show:true,     //该线上的值去掉,
                      position: 'insideEndTop'
                  },
                  lineStyle:{        //设置该线样式
                      normal:{
                          width: '1',
                          type:"dashed",
                          color:"#0055E9",
                          formatter:"全队平均值 : 100"
                      },
                  },
                  data:[{
                          yAxis: (areaOne / numOne).toFixed(1),    //线的值
                          name:"平均值",
                          label: {
                            show: true,
                            formatter: '{b}: {c}'
                          }
                      }]
              },
          }
        ]
      };
      echartsFirst.setOption(option);
      this.$echartsResize(echartsFirst);

      let echartsCenter = this.$echarts.init(
       document.getElementById("echartsCenter")
      );
      let optionC = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.echartsData[6] && this.echartsData[6].map(item => {
              return {
                value: item.date,
                textStyle: {
                    color: '#585D66'
                }
              }
            }),
            axisLabel: {
              interval: 0,
              padding: [10, 0, 0, 0]
            },
            axisLine: {
              lineStyle: {
                color: '#999'
              }
            },
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: '#000'
              }
            },
            nameTextStyle: {
              color: '#000'
            }
        },
        yAxis: {
            type: 'value',
            name: 'kg',
            min: 'dataMin',
            max: 'dataMax',
            axisLine: {
              lineStyle: {
                color: '#585D66'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true
            }
        },
        color: ['#EC8B25'],
        series: [
            {
              type: 'line',
              data: this.echartsData[6] && this.echartsData[6].map(item => {
                return item.value
              }),
              symbol: 'circle', //标记的图形
              symbolSize: 25, // 拐点的大小
              label: {
                normal: {
                  show: true,
                  color: '#fff', // 数字颜色
                  position: ['50%', '35%'],
                  offset: [0, 0],
                  align: 'center'
                }
              },
              markLine:{       //平均线设置
                  silent:true,    //true 去掉鼠标悬浮该线上的动画
                  symbol:"none",   //该线无样式
                  label:{
                      width: '1',
                      show:true,     //该线上的值去掉,
                      position: 'insideEndTop'
                  },
                  lineStyle:{        //设置该线样式
                      normal:{
                          width: '1',
                          type:"dashed",
                          color:"#EC8B25",
                          formatter:"全队平均值 : 100"
                      },
                  },
                  data:[{
                          yAxis: (areaTwo / numTwo).toFixed(1),    //线的值
                          name:"平均值",
                          label: {
                            show: true,
                            formatter: '{b}: {c}'
                          }
                      }]
              },
          }
        ]
      };
      echartsCenter.setOption(optionC);
      this.$echartsResize(echartsCenter);

      let echartsRight = this.$echarts.init(
       document.getElementById("echartsRight")
      );
      let optionR = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.echartsData[5] && this.echartsData[5].map(item => {
              return {
                value: item.date,
                textStyle: {
                    color: '#585D66'
                }
              }
            }),
            axisLabel: {
              interval: 0,
              padding: [10, 0, 0, 0]
            },
            axisLine: {
              lineStyle: {
                color: '#999'
              }
            },
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: '#000'
              }
            },
            nameTextStyle: {
              color: '#000'
            }
        },
        yAxis: {
            type: 'value',
            // name: 'cm',
            min: 'dataMin',
            max: 'dataMax',
            axisLine: {
              lineStyle: {
                color: '#585D66'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true
            }
        },
        color: ['#269A2E'],
        series: [
            {
              type: 'line',
              data: this.echartsData[5] && this.echartsData[5].map(item => {
                return item.value
              }),
              symbol: 'circle', //标记的图形
              symbolSize: 25, // 拐点的大小
              label: {
                normal: {
                  show: true,
                  color: '#fff', // 数字颜色
                  position: ['50%', '35%'],
                  offset: [0, 0],
                  align: 'center'
                }
              },
              markLine:{       //平均线设置
                  silent:true,    //true 去掉鼠标悬浮该线上的动画
                  symbol:"none",   //该线无样式
                  label:{
                      width: '1',
                      show:true,     //该线上的值去掉,
                      position: 'insideEndTop'
                  },
                  lineStyle:{        //设置该线样式
                      normal:{
                          width: '1',
                          type:"dashed",
                          color:"#269A2E",
                          formatter:"全队平均值 : 100"
                      },
                  },
                  data:[{
                          yAxis: (areaThree / numThree).toFixed(1),    //线的值
                          name:"平均值",
                          label: {
                            show: true,
                            formatter: '{b}: {c}'
                          }
                      }]
              },
          }
        ]
      };
      echartsRight.setOption(optionR);
      this.$echartsResize(echartsRight);

      let echartsBot = this.$echarts.init(
       document.getElementById("echartsBot")
      );
      let optionB = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.echartsData[7] && this.echartsData[7].map(item => {
              return {
                value: item.date,
                textStyle: {
                    color: '#585D66'
                }
              }
            }),
            axisLabel: {
              interval: 0,
              padding: [10, 0, 0, 0]
            },
            axisLine: {
              lineStyle: {
                color: '#999'
              }
            },
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: '#000'
              }
            },
            nameTextStyle: {
              color: '#000'
            }
        },
        yAxis: {
            type: 'value',
            name: '次',
            min: 'dataMin',
            max: 'dataMax',
            axisLine: {
              lineStyle: {
                color: '#585D66'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true
            }
        },
        color: ['#FF8181'],
        series: [
            {
              type: 'line',
              data: this.echartsData[7] && this.echartsData[7].map(item => {
                return item.value
              }),
              symbol: 'circle', //标记的图形
              symbolSize: 25, // 拐点的大小
              label: {
                normal: {
                  show: true,
                  color: '#fff', // 数字颜色
                  position: ['50%', '35%'],
                  offset: [0, 0],
                  align: 'center'
                }
              },
              markLine:{       //平均线设置
                  silent:true,    //true 去掉鼠标悬浮该线上的动画
                  symbol:"none",   //该线无样式
                  label:{
                      width: '1',
                      show:true,     //该线上的值去掉,
                      position: 'insideEndTop'
                  },
                  lineStyle:{        //设置该线样式
                      normal:{
                          width: '1',
                          type:"dashed",
                          color:"#FF8181",
                          formatter:"全队平均值 : 100"
                      },
                  },
                  data:[{
                          yAxis: (areaFour / numFour).toFixed(1),    //线的值
                          name:"平均值",
                          label: {
                            show: true,
                            formatter: '{b}: {c}'
                          }
                      }]
              },
          }
        ]
      };
      echartsBot.setOption(optionB);
      this.$echartsResize(echartsBot);
    },
    
    create() {
      let date = this.getFullDate(this.value1)
      this.$axios
        .post(
          "/p/statusrecord/read",
          this.$qs({
            staff_uuid: this.$route.params.uuid,
            date: date
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.echartsData = res.data.data.echarts
            console.log(this.echartsData);
            let fourType = true, fiveType = true, sixType = true, sevenType = true
            res.data.data.status.forEach(item => {
              if(item.type == 4) {
                fourType = false
                this.$set(this.from, 'jump', item.value)
              } else if(item.type == 6) {
                fiveType = false
                this.$set(this.from, 'grip', item.value)
              } else if(item.type == 5) {
                sixType = false
                this.$set(this.from, 'cmj', item.value)
              } else if(item.type == 7) {
                sevenType = false
                this.$set(this.from, 'frecy', item.value)
              }
            })
            fourType ? this.$set(this.from, 'jump', '') : ''
            fiveType ? this.$set(this.from, 'grip', '') : ''
            sixType ? this.$set(this.from, 'cmj', '') : ''
            sevenType ? this.$set(this.from, 'frecy', '') : ''
            this.setEcharts()
          }
        });
    },
    statusChange(value, index) {
      let type = 0
      switch(index) {
        case 4:
              if(value < 0) type = 1
              else if (value > 200) type = 2
              break
        case 6:
              if(value < 0) type = 1
              else if (value > 100) type = 2
              break
        case 5:
              if(value < 0) type = 1
              else if (value > 400) type = 2
              break
        case 7:
              if(value < 0) type = 1
              else if (value > 200) type = 2
              break
      }
      if(type == 1) {
        return this.$message({
                  message: '您输入的结果值可能太小哦！',
                  type: 'error'
                })
      } else if(type == 2) {
        return this.$message({
                  message: '您输入的结果值可能太大哦！',
                  type: 'error'
                })
      }

      let date = this.getFullDate(this.value1)
      this.$axios
        .post(
          "/p/statusrecord/add",
          this.$qs({
            staff_uuid: this.$route.params.uuid,
            date: date,
            type: index,
            value: value == '' ? 0 : value
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: res.data.message
            })
            this.create()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
    },
    valueChange(name) {
      let value = this.from[name]
      var regExp = /^[1-9][0-9]*[.]?([0-9]+)?$/
      if(!regExp.test(value)) {
        this.from[name] = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subjective_view{
  .interMant_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fff;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    .interTop_left {
      width: 70%;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333;
      .el-avatar {
        width: 60px;
        height: 60px;
        img{
          width: 100%;
        }
      }
      span {
        margin-left: 5px;
        margin-right: 20px;
        font-size: 16px;
      }
    }
    .interTop_right{
      > .el-date-editor {
        margin-right: 20px;
        border-radius: 0;
        border-right-width: 0;
        border: none;
        margin-right: 60px;
        /deep/ .el-input__inner{
          border: none;
          border-radius: 0;
          border-bottom: 2px solid #ccc;
          &:focus {
            border-color: #0055E9;
          }
        }
        &:focus {
          border-color: #0055E9;
        }
      }
      > span {
        font-size: 16px;
        margin-right: 20px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.subjective_content{
  background: #fff;
  margin-top: 15px;
  border-radius: 5px;
  padding: 20px 40px 0 40px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
  .content_top_left{
    position: relative;
    width: 49%;
    border-radius: 5px;
    margin-bottom: 30px;
    p{
      padding: 8px 30px;
      font-size: 16px;
      height: 37px;
      line-height: 35px;
      .el-input{
        width: 400px;
        margin-left: 15px;
        border-radius: 15px;
        // float: right;
        // margin-right: 10%;
      }
    }
    .echarts{
      width: 100%;
      height: 300px;
    }
    &:nth-child(1) {
      border: 1px solid #0055E9;
      p{
        background: rgba(0, 85, 233, 0.06);
      }
    }
    &:nth-child(2) {
      border: 1px solid #E97800;
      p{
        background: rgba(192, 100, 2, 0.06);
      }
    }
    &:nth-child(3) {
      border: 1px solid #24C330;
      p{
        background: rgba(39, 156, 48, 0.09);
      }
    }
    &:nth-child(4) {
      border: 1px solid #FF8181;
      p{
        background: rgba(255, 129, 129, 0.06);
      }
    }
  }
}
.otim{
     white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all; 
}

</style>


<style lang="scss">
.custom_date_class {
  span {
    background: #ea6151;
    color: red;
    border-radius: 50%;
    color: #fff !important;
    &:hover {
      background-color: #409eff;
    }
  }
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    font-size: 12px;
    color: red;
    bottom: -15px;
    left: 0;
    text-align: center;
    white-space: nowrap;
  }
}


</style>