import { render, staticRenderFns } from "./sportsExpressionDetail.vue?vue&type=template&id=85fb8b16&scoped=true&"
import script from "./sportsExpressionDetail.vue?vue&type=script&lang=js&"
export * from "./sportsExpressionDetail.vue?vue&type=script&lang=js&"
import style0 from "./sportsExpressionDetail.vue?vue&type=style&index=0&id=85fb8b16&prod&lang=scss&scoped=true&"
import style1 from "./sportsExpressionDetail.vue?vue&type=style&index=1&id=85fb8b16&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85fb8b16",
  null
  
)

export default component.exports